<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">신청년도</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    :dataSource="dropdownSource.year"
                    :fields="commonCodeFields"
                    v-model="searchOptions.requestYear"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">신청상태</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    :dataSource="dropdownSource.status"
                    :fields="commonCodeFields"
                    v-model="searchOptions.grpRequestStatus"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">단체종류</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    :dataSource="dropdownSource.kind"
                    :fields="commonCodeFields"
                    v-model="searchOptions.grpKind"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">단체 / 총무명</div>
            <ul class="content">
              <li class="item">
                <input-text
                    v-model="searchOptions.name"
                    style="width: 200px;"
                />
              </li>
            </ul>
          </li>
        </ul>

        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="fetch"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="searchDetail"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">연 단체 접수 목록</div>
              <div class="header-caption">[{{ numberWithCommas(count) }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="selectClick"
                  >
                    선택처리
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :is-shortcut-button="true"
                      @click.native="onSelectCellDeleteClicked"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body" style="border: none;">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="groupReceiptStatus"
                  v-bind="gridOptions"
                  @queryCellInfo="onGridQueryCellInfo"
                  @headerCellInfo="onGridHeaderCellInfo"
                  @recordClick="onRecordClick"
                  @actionComplete="onGridActionComplete"
                  :dataSource="dataList"
                  :allowExcelExport="true"
                  @rowDataBound="onRowDataBound"
              >
              </ejs-grid-wrapper>
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
        ref="searchDetailPopup"
        :header="`상세검색`"
        :allowDragging="true"
        :showCloseIcon="true"
        width="387"
        :animationSettings="{ effect: 'None' }"
        :isModal="false"
        :visible="false"
        enableResize="true"
        v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-blacklistRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">신청팀수</div>
                  <ul class="content">
                    <li class="item input">
                      <input-text
                          v-model="searchOptions.requestTeam"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">희망월</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          v-model="searchOptions.resveMm"
                          :dataSource="dropdownSource.resveMm"
                          :allowFiltering="false"
                          :fields="commonCodeFields"
                          cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">예약주기</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          v-model="searchOptions.resveCycle"
                          :dataSource="dropdownSource.resveCycle"
                          :allowFiltering="false"
                          :fields="commonCodeFields"
                          cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">요일</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          v-model="searchOptions.dwCode"
                          :dataSource="dropdownSource.dwCode"
                          :allowFiltering="false"
                          :fields="commonCodeFields"
                          cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">희망시간</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          v-model="searchOptions.grpHopeTime"
                          :dataSource="dropdownSource.grpHopeTime"
                          :allowFiltering="false"
                          :fields="commonCodeFields"
                          cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">단체구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          v-model="searchOptions.grpDiv"
                          :dataSource="dropdownSource.grpDiv"
                          :allowFiltering="false"
                          :fields="commonCodeFields"
                          cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">기타요청</div>
                  <ul class="content">
                    <li class="item input">
                      <input-text
                          v-model="searchOptions.etcRequstContents"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  v-on:click.native="fetch"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onDialogSearchConditionInitClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onDialogCloseButtonClicked">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <group-details-popup
        ref="groupDetailsPopup"
        v-if="isGroupDetailsPopup"
        @popupClosed="close"
    />
    <application-change-status-popup
        ref="applicationChangeStatusPopup"
        v-if="isApplicationChangeStatusPopup"
        @popupClosed="changePopupClose"
        @fetch="fetch"
    />
  </div>
</template>

<script>
import GroupDetailsPopup from "@/views/golf-reservation/popup/GroupDetailsPopup";
import inputText from "@/components/common/text/InputText";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Edit, ExcelExport, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {commonCodesGetColorValue, commonCodesGetCommonCode} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getBatchCurrentViewRecords, gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import ApplicationChangeStatusPopup from "@/views/golf-reservation/popup/ApplicationChangeStatusPopup";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "GroupReceiptStatus",
  components:{
    EjsGridWrapper,
    inputText,
    GroupDetailsPopup,
    ApplicationChangeStatusPopup,
    ErpButton,
  },
  mixins: [confirmDialogMixin,commonMixin],
  data() {
    return{
      rowSelectBuffer:null,
      commonCodeFields: {text: 'comName', value: 'comCode'},
      dropdownSource:{
        year:commonCodesGetCommonCode("REQUEST_YEAR"),
        status:commonCodesGetCommonCode("GRP_REQUEST_STATUS"),
        kind:commonCodesGetCommonCode("GRP_KIND"),

        resveMm:commonCodesGetCommonCode("RESVE_MM"),
        dwCode:commonCodesGetCommonCode("DW_CODE"),
        grpHopeTime:commonCodesGetCommonCode("GRP_HOPE_TIME"),
        resveCycle:commonCodesGetCommonCode("RESVE_CYCLE"),
        grpDiv:commonCodesGetCommonCode("GRP_DIV")
      },
      searchOptions:{
        requestYear:'',
        grpKind:'',
        grpRequestStatus:'',
        name:'',
        requestTeam:"",
        resveMm:"",
        dwCode:"",
        grpHopeTime:"",
        resveCycle:"",
        grpDiv:"",
        etcRequstContents:"",
      },
      count:0,
      dataList:[],
      isSearchDetailPopupOpen:false,
      isGroupDetailsPopup:false,
      isApplicationChangeStatusPopup:false
    };
  },
  created() {
    this.dropdownSource.year.unshift({
      'comName':'전체',
      'comCode':''
    });
    this.dropdownSource.status.unshift({
      'comName':'전체',
      'comCode':''
    });
    this.dropdownSource.kind.unshift({
      'comName':'전체',
      'comCode':''
    });

    this.dropdownSource.resveMm.unshift({
      'comName':'전체',
      'comCode':''
    });
    this.dropdownSource.dwCode.unshift({
      'comName':'전체',
      'comCode':''
    });
    this.dropdownSource.grpHopeTime.unshift({
      'comName':'전체',
      'comCode':''
    });
    this.dropdownSource.resveCycle.unshift({
      'comName':'전체',
      'comCode':''
    });
    this.dropdownSource.grpDiv.unshift({
      'comName':'전체',
      'comCode':''
    });
  },
  computed:{
    gridOptions() {
      return{
        provides: [Edit, ForeignKey, Resize, ExcelExport,Page],
        allowPaging: true,
        pageSettings: {pageSize: 50},
        selectionSettings: {
          persistSelection: true,
          type: "Single",
          checkboxOnly: true,
          checkboxMode: "Default",
          enableToggle: false,
        },
        editSettings: {
          allowEditing: true,
          mode: "Batch",
          showConfirmDialog: false,
        },
        isAutoSelectRow: false,
        isAutoSelectCell: false,
        isSelectedRowRetain: false,
        columns:[
          {
            field: "flag",
            type: "checkbox",
            minWidth: 16,
            width: 40,
            textAlign: "Center",
            allowEditing: false,
            allowFiltering: false,
          },
          {
            field:"grpRequestStatus",
            headerText:"신청상태",
            type:"string",
            textAlign:"Center",
            width:80,
            groupCode: "GRP_REQUEST_STATUS",
            isCommonCodeField: true,
            allowEditing: false,
          },
          {
            field:"requestYear",
            headerText:"신청년도",
            type:"string",
            textAlign:"Center",
            width:100,
            allowEditing: false,
          },
          {
            field:"grpKind",
            headerText:"단체종류",
            type:"string",
            textAlign:"Center",
            width:100,
            allowEditing: false,
            groupCode:"GRP_KIND",
            isCommonCodeField: true,
          },
          {
            field:"grpRequestId",
            headerText:"신청번호",
            type:"string",
            textAlign:"Center",
            width:100,
            allowEditing: false,
          },
          {
            field:"approvalGrpNo",
            headerText:"승인단체번호",
            type:"string",
            textAlign:"Center",
            width:100,
            allowEditing: false,
          },
          {
            field:"grpName",
            headerText:"단체명",
            type:"string",
            textAlign:"left",
            width:220,
            allowEditing: false,
          },
          {
            field:"requestTeam",
            headerText:"신청팀수",
            type:"string",
            textAlign:"Center",
            width:100,
            allowEditing: false,
            valueAccessor:(field, data) => {
              return data[field]+"팀";
            }
          },
          {
            field:"resveMm",
            headerText:"희망월",
            type:"string",
            textAlign:"Center",
            width:80,
            allowEditing: false,
            valueAccessor:(field, data) => {
              return  data[field]+"월";
            }
          },
          {
            field:"resveCycle",
            headerText:"예약주기",
            type:"string",
            textAlign:"Center",
            width:80,
            allowEditing: false,
            valueAccessor:(field, data) => {
             return data[field]+"주";
            }
          },
          {
            field:"dwCode",
            headerText:"예약요일",
            type:"string",
            textAlign:"Center",
            width:80,
            groupCode:"DW_CODE",
            isCommonCodeField: true,
            allowEditing: false,
          },
          {
            field:"grpHopeTime",
            headerText:"희망시간",
            type:"string",
            textAlign:"left",
            width:180,
            groupCode:"GRP_HOPE_TIME",
            isCommonCodeField: true,
            allowEditing: false,
          },
          {
            field:"grpDiv",
            headerText:"단체구분",
            type:"string",
            textAlign:"Center",
            width:120,
            groupCode:"GRP_DIV",
            isCommonCodeField: true,
            allowEditing: false,
          },
          {
            field:"grpRceptMemberCount",
            headerText:"단체인원",
            type:"string",
            textAlign:"Center",
            width:80,
            allowEditing: false,
            valueAccessor:(field, data) => {
              if(data[field]){
                return data[field]+"명";
              }else {
                return null;
              }
            }
          },
          {
            field:"generName",
            headerText:"총무명",
            type:"string",
            textAlign:"Center",
            width:80,
            allowEditing: false,
          },
          {
            field:"generMemberDiv",
            headerText:"회원구분",
            type:"string",
            textAlign:"Center",
            width:120,
            allowEditing: false,
            groupCode:"MEMBER_DIV",
            isCommonCodeField: true,
          },
          {
            field:"generContactTel",
            headerText:"총무연락처",
            type:"string",
            textAlign:"Center",
            width:120,
            allowEditing: false,
            valueAccessor: this.contactTelValueAccess,
          },
          {
            field:"generAreaCode",
            headerText:"총무지역",
            type:"string",
            textAlign:"Center",
            width:100,
            allowEditing: false,
            groupCode:"AREA_CODE",
            isCommonCodeField: true,
          },
          {
            field:"generSigunName",
            headerText:"총무시군구",
            type:"string",
            textAlign:"Center",
            width:100,
            allowEditing: false,
          },
          {
            field:"generHomeAddr",
            headerText:"총무주소",
            type:"string",
            textAlign:"left",
            width:350,
            allowEditing: false,
          },
          {
            field:"chairName",
            headerText:"회장명",
            type:"string",
            textAlign:"Center",
            width:80,
            allowEditing: false,
          },
          {
            field:"chairMemberDiv",
            headerText:"회원구분",
            type:"string",
            textAlign:"Center",
            width:120,
            allowEditing: false,
            groupCode:"MEMBER_DIV",
            isCommonCodeField: true,
          },
          {
            field:"chairContactTel",
            headerText:"회장연락처",
            type:"string",
            textAlign:"Center",
            width:120,
            allowEditing: false,
            valueAccessor: this.contactTelValueAccess,
          },
          {
            field:"etcRequstContents",
            headerText:"기타요청내용",
            type:"string",
            textAlign:"left",
            width:300,
            allowEditing: false,
          },
          {
            field:"remarks",
            headerText:"비고",
            type:"string",
            textAlign:"left",
            width:120,
            allowEditing: false,
          },
          {
            field:"insertName",
            headerText:"등록자",
            type:"string",
            textAlign:"Center",
            width:120,
            allowEditing: false,
          },
          {
            field:"insertDt",
            headerText:"등록일시",
            type:"string",
            textAlign:"Center",
            width:130,
            allowEditing: false,
          },
          {
            field:"updateName",
            headerText:"수정자",
            type:"string",
            textAlign:"Center",
            width:120,
            allowEditing: false,
          },
          {
            field:"updateDt",
            headerText:"수정일시",
            type:"string",
            textAlign:"Center",
            width:130,
            allowEditing: false,
          },
        ]
      };
    }
  },
  methods: {
    numberWithCommas,
    contactTelValueAccess: gridUtilGetTelColumnAccess,
    getBatchCurrentViewRecords,
    onRowDataBound(args) {
      if(args.data.etcRequstContents) {
        const stringLength = args.data.etcRequstContents.split("").length/25;
        args.rowHeight = 24*(Math.ceil(stringLength)) ;
      }
    },
    async fetch() {
      this.$refs.groupReceiptStatus?.clearSelection();

      this.initAllSelectionCells();

      const data = (await GolfErpAPI.annualGroupRegistrationStatus(this.searchOptions)).map(data=>({
        ...data,
        generHomeAddr:(data.generHomeAddr1 && data.generHomeAddr2) ? data.generHomeAddr1+data.generHomeAddr2
        : (data.generHomeAddr1) ? data.generHomeAddr1 : "",
      }));
      this.dataList = data;
    },
    removeSelectionCells() {
      Array.from(document.querySelectorAll(".e-selectionbackground"))
          .filter((el) => !el.hasAttribute("aria-selected"))
          .forEach((el) => {
            el.classList.remove("e-selectionbackground");
            el.classList.remove("e-active");
          });
    },
    createFocusedCells() {
      const focusedRowCells =
          this.$refs.groupReceiptStatus.getRows()[this.rowSelectBuffer]
              ?.cells;
      if (focusedRowCells && focusedRowCells?.length > 0) {
        Array.from(focusedRowCells).forEach((el) => {
          el.classList.add("e-selectionbackground");
          el.classList.add("e-active");
        });
      }
    },
    initAllSelectionCells() {
      this.rowSelectBuffer = null;
      Array.from(document.querySelectorAll(".e-selectionbackground")).forEach(
          (el) => {
            el.classList.remove("e-selectionbackground");
            el.classList.remove("e-active");
          }
      );
    },
    searchDetail() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    selectClick() {
      const list = [];
      const arr = this.$refs.groupReceiptStatus.getSelectedRecords();
      arr.map(data=>{
        for (let key in data){
          if(key==="grpRequestId" && key){
            list.push(data[key]);
          }
        }
      });
      if(!list.length){
        this.errorToast("선택된 사항이 없습니다");
        return;
      }
      this.isApplicationChangeStatusPopup = true;
      this.$nextTick(() => {
            this.$refs["applicationChangeStatusPopup"].showPopup(list);
      });
      },
    async onSelectCellDeleteClicked() {
      const arr = this.$refs.groupReceiptStatus.getSelectedRecords();
      const delList = arr.map((data)=> data.grpRequestId);
      if (delList.length === 0) {
        this.errorToast("삭제할 데이터를 선택해 주세요");
        return;
      }
      if (!(await this.confirm("삭제하시겠습니까?"))) {
        return;
      }
      await GolfErpAPI.deleteGroupRegistration(delList);

      await this.fetch();
    },
    onGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
          },
        },
        node,
      } = args;
      if (field === "grpRceptMemberCount") {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
          allowEditing,
        },
        data,
      } = args;
      if (allowEditing) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === "flag") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === 'dwCode') {
        cell.style.color = commonCodesGetColorValue("DW_CODE", data.dwCode);
      } else if (field === 'grpRequestStatus') {
        cell.style.color = commonCodesGetColorValue("GRP_REQUEST_STATUS", data.grpRequestStatus);
      }
      if (field === "grpRceptMemberCount" && data.grpRceptMemberCount) {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if(field === "etcRequstContents") {
        cell.style.whiteSpace = "normal";
        cell.style.lineHeight = "inherit";
      }
    },
    onRecordClick(args) {
      const {
        column: { field },
        rowData:data
      } = args;
      if(args.column){
        this.removeSelectionCells();
        if(field !== "flag"){
          this.rowSelectBuffer = args.rowIndex;
          this.createFocusedCells();
        }
      }
      if (args.column && args.column.field === "grpRceptMemberCount" && data.grpRceptMemberCount) {
        this.isGroupDetailsPopup = true;
        this.$nextTick(() => {
          const title = {
            generName:data.generName,
            grpRequestStatus:data.grpRequestStatus,
            grpName:data.grpName
          };
          this.$refs.groupDetailsPopup.showPopup(args.rowData.grpRequestId,title);
        });
      }
    },
    onGridActionComplete() {
      this.count = this.$refs.groupReceiptStatus?.getGridBatchCount() || 0  ;
    },
    onClickExcel() {
      this.$refs.groupReceiptStatus.excelExport();
    },
    onDialogSearchConditionInitClicked() {
       this.searchOptions = {
         requestTeam:"",
         resveMm:"",
         dwCode:"",
         grpHopeTime:"",
         resveCycle:"",
         grpDiv:"",
         etcRequstContents:"",
      };
    },
    onDialogCloseButtonClicked() {
      this.isSearchDetailPopupOpen = false;
    },
    close() {
      this.isGroupDetailsPopup = false;
    },
    changePopupClose() {
      this.isApplicationChangeStatusPopup = false;
    }
  }
};
</script>

<style scoped>

</style>