<template>
  <div id="popupWrapper">
    <ejs-dialog
        ref="roomNumberRegistrationPopup"
        header="단체인원상세"
        width="479"
        allowDragging="true"
        showCloseIcon="true"
        isModal="false"
        enableResize="true"
        @close="close"
    >

      <div class="window advancePaymentDepositInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-header">
              <div class="title">
                {{title.grpName}} ({{commonCodesGetComName('GRP_REQUEST_STATUS',title.grpRequestStatus)}}) 총무 : {{title.generName}}
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">단체 인원 목록</div>
                      <div class="header-caption">[{{count}}건]</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                          v-bind="gridOptions"
                          :dataSource="dataList"
                      >
                      </ejs-grid-wrapper>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="hideClose"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Edit, ExcelExport, ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import {gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import {commonCodesGetComName} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "GroupDetailsPopup",
  components:{
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return{
      title:{},
      dataList:[],
      count:0,
    };
  },
  computed:{
    gridOptions() {
      return{
        provides: [Edit, ForeignKey, Resize, ExcelExport],
        selectionSettings: {
          type: "Multiple",
          mode: "Both",
        },
        editSettings: {
          allowEditing: false,
          mode: "Batch",
        },
        columns:[
          {
            field:"memberName",
            headerText:"회원명",
            type:"string",
            textAlign:"Center",
            width:120,
          },
          {
            field:"memberDiv",
            headerText:"회원구분",
            type:"string",
            textAlign:"Center",
            width:120,
            groupCode: "MEMBER_DIV",
            isCommonCodeField: true,
          },
          {
            field:"hpNo",
            headerText:"연락처",
            type:"string",
            textAlign:"Center",
            width:120,
            valueAccessor: this.contactTelValueAccess,
          },
        ]
      };
    }
  },
  methods:{
    commonCodesGetComName,
    contactTelValueAccess: gridUtilGetTelColumnAccess,
    async showPopup(data,title) {
      const list = await GolfErpAPI.getGroupDetailedInquiry(data);
      this.title = title;
      this.dataList = list.map(data=>{
        return{
          ...data,
          memberDiv:data.membership?.memberDiv
        };
      });
      this.count = list.length;
    },
    async close() {
      await this.$emit("popupClosed");
    },
    async hideClose() {
      await this.$refs["roomNumberRegistrationPopup"].hide();
    },
  }
};
</script>

<style scoped>
.content-header {
  padding: 10px;
}
.content-header > .title {
  border-bottom: 2px solid #ccc;
  font-size: 16px;
  height: 35px;
  line-height: 35px;
  font-family: 'NanumSquare-Bold';
}
</style>