<template>
  <div id="popupWrapper">
    <ejs-dialog
        ref="applicationChangeStatusPopup"
        header="신청상태 변경"
        width="200"
        allowDragging="true"
        showCloseIcon="true"
        isModal="false"
        enableResize="true"
        @close="close"
    >

      <div class="window advancePaymentDepositInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">신청 상태 변경</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <div class="button-field">
                        <erp-button
                          button-div="SAVE"
                          v-for="data in buttonSource"
                          :key="data"
                          :style="`backgroundColor:${data.colorValue}; color:${data.comCode !=='RCEPT'&& 'white'}`"
                          @click.native="statusChangeClick(data.comCode)"
                        >
                          {{data.comName}}
                        </erp-button>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="hideClose"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "ApplicationChangeStatusPopup",
  mixins: [confirmDialogMixin,commonMixin],
  components: {
    ErpButton,
  },
  data() {
    return{
      buttonSource:commonCodesGetCommonCode("GRP_REQUEST_STATUS"),
      dataList:[],
      status:null,
    };
  },
  methods:{
    async close() {
      if(this.status==="OK"){
        await this.$emit("fetch");
      }
      await this.$emit("popupClosed");
    },
    async hideClose() {
      await this.$refs["applicationChangeStatusPopup"].hide();
    },
    async showPopup(data) {
      this.dataList = data;
    },
    async statusChangeClick(comCode) {
      this.status = await GolfErpAPI.patchGroupReceptionStatusChange(comCode,this.dataList);
      (this.status === "OK") && this.infoToast("변경되었습니다");
    }
  }
};
</script>

<style scoped>
.button-field {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 10px;
  width: 100%;
  height: 100%;
  align-items: center;
}
.button-field > .e-btn {
  margin-bottom: 10px;
  border-radius: 5px !important;
  width: 90%;
  height: 35px;
  font-family: 'NanumSquare-Bold';
}
</style>
